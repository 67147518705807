/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Tallinn"), React.createElement("p", null, "Pour se loger, Tallinn s’articule en cinq quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ee/tallinn/oldtown.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "La vieille ville"), " : la ville médiévale se partage entre la colline de Toompea, la ville haute et la ville basse."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ee/tallinn/kesklinn.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kesklinn"), " : le centre de Tallinn regroupe la vieille ville, le port et les quartiers les plus récents."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ee/tallinn/pohjatallinn.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Põhja-Tallinn"), ", au nord : gare de Tallinn-Baltique et quartier résidentiel de Kalamaja."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ee/tallinn/kristiine.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kristiine"), ", à l’ouest : quartier tranquille, à proximité du centre commercial Kristiine Centre."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/ee/tallinn/lasnamae.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Lasnamäe"), ", à l’est : zone résidentielle héritée de l’époque soviétique et aéroport de Tallinn."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
